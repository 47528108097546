html {
  font-size: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #fefbfc;
}

#root {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: 100vh;
  font-family: 'Fira Sans';
}

.container {
  width: 100%;
  margin: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

p a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: underline solid;
}

.fill-current path {
  fill: currentColor;
}

.daterangepicker {
  color: #2e384d !important;
  font-family: 'Fira Sans' !important;
}
.daterangepicker .calendar-table > table > tbody > tr > td.active,
.daterangepicker .calendar-table > table > tbody > tr > td.active.start-date,
.daterangepicker .calendar-table > table > tbody > tr > td.active.end-date,
.daterangepicker .calendar-table > table > tbody > tr > td.active.off.ends.end-date {
  background-color: #6D0FB1;
}
.daterangepicker .calendar-table > table > tbody > tr > td.in-range,
.daterangepicker .calendar-table > table > tbody > tr > td.active.off.ends {
  background-color: #F0E7F7;
}
